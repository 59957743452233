<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <img src="/logo-new.png" alt="Logo" style="width: 2rem; height: 2rem;">
        <h2 class="brand-text text-success ml-1">
          Habit
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex flex-column align-items-center justify-content-center px-5">
          <!-- <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          /> -->
          <!-- <vuexy-logo :size="200"/> -->
          <img src="/logo-new.png" alt="Logo" style="width: 10rem; height: 10rem;">
          <h6 class="mt-4 text-success text-center" style="line-height: 1.5;">
             {{ $t('message.msgSlogan1') }}<br>{{ $t('message.msgSlogan2') }}
          </h6>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold text-primary"
            title-tag="h3"
          >
            {{ $t('message.welcomeToHabit') }}! 👋
          </b-card-title>

          <add-to-home-screen :responsive="false" class="mt-2 mb-2"/>

          <span class="mb-2">
            {{ $t('message.msgLogin') }}
          </span>

          <!-- <b-alert
            variant="primary"
            show
          >
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                :label="$t('message.Email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Email')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('message.Password') }}</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>{{ $t('message.ForgotPassword') }}?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Password')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :name="$t('message.Password')"
                      :placeholder="$t('message.Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                ><small>{{ $t('message.RememberMe') }}</small>   
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-2"
                block
                :disabled="invalid"
              >
                {{ $t('message.SignIn') }}
              </b-button>
            </b-form>
          </validation-observer>

          <!-- New account -->
          <b-card-text v-if="inDemo" class="text-left mt-2">
            <span>{{ $t('message.NewOnOurPlatform') }}? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span
                class="text-primary"
                style="font-weight: 500; text-decoration: underline; text-underline-offset: 0.2em;">
                &nbsp;{{ $t('message.CreateAnAccount') }}
              </span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->

          <!-- Loading -->
          <loading
            v-if="isLoading"
            :active="true" 
            :is-full-page="false"
            :color="colors.primary"
          />
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import HabitLogo from '@core/layouts/components/Logo.vue'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddToHomeScreen from '@/views/habit/AddToHomeScreen.vue';
import i18n from '@/libs/i18n'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import useNotifications from '@/composables/useNotifications'
import { onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { colors } from '@/constants'
import endpoints from '@/libs/endpoints'
import realmConnection from '@/views/habit/realm'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    HabitLogo,
    ValidationProvider,
    ValidationObserver,
    AddToHomeScreen,
    Loading,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      userEmail: '',
      required,
      email,
      isLoading: false,
      colors,
    }
  },
  setup() {
    const { route } = useRouter()
    const { showErrorMessage } = useNotifications()
    const { getItem, getItemsWithAggregate, ObjectId } = realmConnection()
    const inDemo = endpoints.app_id === process.env.VUE_APP_APP_ID_DEMO

    onMounted(() => {
      if (route.value.query.session === "expired") {
        showErrorMessage(i18n.t('message.session_expired'))
      }
    })
    
    return {
      inDemo,
      getItem,
      getItemsWithAggregate,
      ObjectId
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(async () => {
              try {
                const {
                  custom_data: {
                    role, ability, extras, username, worker_id = null, client_id = null, project = '', expirationDate
                  },
                  deleted = false
                } = useJwt.realm.currentUser.customData
                const { id, accessToken, refreshToken } = useJwt.realm.currentUser
                if (deleted) {
                  const errors = { email: ["Invalid Email"], password: ["Invalid Password"]}
                  this.$refs.loginForm.setErrors(errors)
                  useJwt.logout()
                  this.isLoading = false
                  return false;
                }
                useJwt.setToken(accessToken)
                useJwt.setRefreshToken(refreshToken)

                if (role == 'supervisor' || role == 'consultant') {
                  // Get worker data
                  const workerId = worker_id?.$oid || ''

                  const pipeline = [
                    { $match: { _id: this.ObjectId(workerId) } },
                    { $lookup: { from: 'location', localField: 'locations', foreignField: '_id', pipeline: [ { $project: { location: 1 } } ], as: 'locationsData' } },
                  ]
                
                  const items = await this.getItemsWithAggregate({ collection: 'worker', pipeline })
                  if (!items?.[0]) throw new Error('Item not found')

                  const workerData = items[0]
                  const clientId = workerData.client_id
                  const userData = {
                    id,
                    fullName: username || '',
                    username,
                    avatar: require('@/assets/images/avatars/13-small.png'),
                    email: this.userEmail,
                    role,
                    ability,
                    extras,
                    worker_id,
                    project,
                    client: clientId ? { $oid: clientId.toString() } : null,
                    expirationDate,
                    locations: workerData.locationsData?.map(({ location, _id }) => ({ title: location, value: _id.toString() })) || []
                  }
                  localStorage.setItem('userData', JSON.stringify(userData))

                  // Get client data
                  const clientData = await this.getItem({ collection: 'client', query: { _id: clientId } })
                  if (!clientData) throw new Error('Client not found')

                  if (clientData.default_language) this.$i18n.locale = clientData.default_language
                  localStorage.setItem('clientData', JSON.stringify(clientData))
                  const initialRoute = this.$route.query.from || (clientData.default_view_pc_team ? '/apps/pc-team' : clientData.default_view_daily_dialogue ? '/habit/meeting/new' : '/apps/calendar')
                  this.$router.replace(initialRoute)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `${i18n.t('Welcome')} ${username || ''}`,
                          icon: 'CoffeeIcon',
                          variant: 'primary',
                          text: `${i18n.t('Successful Login As')} ${i18n.t(`userRoles.${role}`)}. ${i18n.t('Start To Explore')}!`,
                        },
                      })
                    })
                    .catch((err) => {
                      console.log(err);
                      this.isLoading = false
                    })
                  this.$ability.update(ability || [])
                  this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', extras?.eCommerceCartItemsCount || 0)
                  this.$store.commit('userStore/updateUserData', userData)
                } else {
                  const userData = {
                    id,
                    fullName: username || '',
                    username,
                    avatar: require('@/assets/images/avatars/13-small.png'),
                    email: this.userEmail,
                    role,
                    ability,
                    extras,
                    worker_id,
                    project,
                    client: client_id || null
                  }
                  
                  localStorage.setItem('userData', JSON.stringify(userData))
                  this.$ability.update(ability || [])

                  this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', extras?.eCommerceCartItemsCount || 0)
                  this.$store.commit('userStore/updateUserData', userData)

                  this.$router.replace(this.$route.query.from || '/apps/calendar')
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${username || ''}`,
                          icon: 'CoffeeIcon',
                          variant: 'primary',
                          text: `You have successfully logged in as ${role}. Now you can start to explore!`,
                        },
                      })
                    })
                    .catch((err) => {
                      console.log(err);
                      this.isLoading = false
                    })
                }
              }
              catch(e) {
                console.error(e)
                this.isLoading = false
              }
            })
            .catch(error => {
              console.log(error)
              const errors = {
                password: [], email: [],
              }
              if (error.errorCode === 'InvalidPassword') {
                errors.password = [error.error]
              }
              if (error.errorCode === 'InvalidEmail') {
                errors.email = [error.error]
              }
              this.$refs.loginForm.setErrors(errors)
              this.isLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
