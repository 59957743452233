<template>
    <div>
      <b-button
        v-if="deferredPrompt"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :size="'md'"
        :block="true"
        :class="responsive ? 'd-none' : 'd-block'"
        @click="clickCallback"
      >
        {{ $t('install_app') }}
      </b-button>
      <b-nav-item
        :class="responsive ? 'd-sm-block d-none' : 'd-none'"
        @click="clickCallback"
      >
        <b-button
            v-if="deferredPrompt"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            v-b-tooltip.hover.v-primary.bottom="$t('install_app')"
            class="btn-icon rounded-circle"
        >
          <feather-icon
            size="19"
            icon="DownloadIcon"
          />
        </b-button>
      </b-nav-item>
    </div>
  </template>
  
  <script>
  import { BButton, BNavItem } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { VBTooltip } from 'bootstrap-vue'

  export default {
    name: 'AddToHomeScreen',
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    components: {
      BButton,
      BNavItem
    },
    props: {
      responsive: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      deferredPrompt: null,
    }),
    mounted() {
      this.captureEvent()
    },
    methods: {
      captureEvent() {
        window.addEventListener('beforeinstallprompt', (e) => {
          // ! Prevent Chrome 67 and earlier from automatically showing the prompt
          e.preventDefault()
          // Stash the event so it can be triggered later..
          this.deferredPrompt = e
        })
      },
      clickCallback() {
        // Show the prompt
        this.deferredPrompt.prompt()
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            // Call another function?
          }
          this.deferredPrompt = null
        })
      },
    },
  }
  </script>